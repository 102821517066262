/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('checks', {
      parent: 'root',
      redirectTo: '.main',
      url: '',
      template: '<ui-view />',
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.checks" */ 'sections/checks'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('checks.main', {
      url: '/checks',
      templateUrl: 'sections/checks/templates/acp-checks.ng.html'
    })
    .state('checks.order', {
      url: '/checks/order',
      templateUrl: 'sections/checks/templates/acp-checks-order.ng.html',
      data: {
        permissions: {
          except: [
            'isCIPConditionalDocumentsNotUploaded',
            'isCIPConditionalDocumentsUploaded'
          ],
          redirectTo: {
            isCIPConditionalDocumentsNotUploaded: 'dashboard',
            isCIPConditionalDocumentsUploaded: 'dashboard'
          }
        }
      }
    })
    .state('checks.pre-fund', {
      url: '/checks/pre-fund',
      templateUrl: 'sections/checks/templates/acp-checks-pre-fund.ng.html'
    })
    .state('checks.manage', {
      url: '/checks/manage?id',
      templateUrl: 'sections/checks/templates/acp-checks-manage.ng.html'
    });
}

export default states;
